<template>
    <div>
        <b-card>
            <dynamic-table-ag-grid
                ref="ordersTable"
                table-key="ct_key_orders_table"
                endpoint="orders"
                :records-per-page="100"
            />
        </b-card>
    </div>
</template>

<script>
import DynamicTableAgGrid from '../../components/Misc/DynamicTable/DynamicTableAgGrid.vue'
import {
    BCard,
} from 'bootstrap-vue'

export default {
    name: 'orders.index',
    components: {
        DynamicTableAgGrid,
        BCard,
    },
    data() {
        return {
            currentLayout: [],
        }
    },
    computed: {

    },
}
</script>
